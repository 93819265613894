.main-frame {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 30px;
    max-width: 700px;
    min-width: fit-content;
    background-color: #244630;
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    align-items: center;
}

.profile-inner {
    display: flex;
}

.profile-left {
    margin-right: 15px;
}

.profile-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-image {
    width: 130px;
    height: 130px;
    box-sizing: border-box;
}

.profile-image img {
    border-radius: 100%;
    border: 2px solid #ff9528;
}

.display-name-container {
    font-size: 2rem;
}

.sponsor-tag {
    color: #ff9528;
    margin-right: 10px;
    font-weight: 300;
}

.display-name {
    color: #d6e8e7;
    text-transform: uppercase;
}

.pronoun-container {
    text-align: left;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 5px 10px;
    font-size: 1rem;
    color: #244630;
    background-color: #ff9528;
    border-radius: 15px;
    width: fit-content;
}

.actions-container {
    position: absolute;
    top: 15px;
    right: 15px;
}

.light-button {
    color: #d6e8e7 !important;
}

.additional-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 15px;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 25px;
    min-width: fit-content;
}

.caster-table {
    width: fit-content !important;
}