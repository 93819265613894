.form-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-item {
    min-width: 500px;
    margin-bottom: 25px;
}

.icon-spacing {
    margin-right: 15px;
}