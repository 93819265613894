#expense-add-center {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;
}

#expense-add-root {
    max-width: 1vw;
    min-width: 35rem;
}
