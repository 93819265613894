#app-header-container {
    background-color: #244630;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    color: #d6e8e7;
}

#app-title {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 30px;
    transform: translateY(-2px);
}

#app-header-left {
    display: flex;
}

#app-header-right {
    color: #d6e8e7;
}

#level1-logo {
    height: 100%;
    width: 230px;
}

#wordmark-only {
    height: 100%;
    width: 71px;
}

.MuiToggleButton-secondary:not(.Mui-selected) {
    color: #d6e8e7 !important;
}

.MuiToggleButton-secondary {
    border: 1px solid #d6e8e720 !important;
}