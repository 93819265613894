#expense-panel-flex {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.flex-card {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-card .MuiCardContent-root {
    text-align: left;
    max-width: 200px;
}

.MuiSvgIcon-root {
    margin-left: 4px;
}
